import React from 'react'

import {
  getMenus, getOptions,
} from 'api'
import { normalizeMetadata, } from 'api/utils'

import { default as normalizeFooterData } from 'models/Footer'
import { default as normalizeHeaderData } from 'models/Header'

import Layout from '@/organisms/Layout'
import ErrorPage from '@/organisms/ErrorPage'

import DefaultLayout from 'layouts/default'

export const getStaticProps = async () => {
  const [menus = {}, options] = await Promise.all([
    getMenus(),
    getOptions(),
  ])

  const data = { title: 'Pagina non trovata - Lactalis valore Italia', }

  const metadata = await normalizeMetadata(data)
  const footer =  normalizeFooterData(menus.footer)
  const header = normalizeHeaderData(menus.header)

  return {
    props: {
      metadata,
      options: {

        isHomepage: false,
        lateral: null,
        ...options,
      },
      currentPage: null,
      menus: {
        footer,
        header,
      },
    }
  }
}


export default function NotFound({ metadata }) {
  return (
    <DefaultLayout metadata={metadata}>
      <Layout>
        <ErrorPage />
      </Layout>
    </DefaultLayout>
  )
}
