import styled from 'styled-components'

export const StyledErrorPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
  position: relative;

  ${({ theme }) => theme.mediaQuery.md(`
    min-height: 100vh;
  `)}

  .ErrorWave{
    &::before{
      content: '';
      display: table;
      width: 100%;
      height: 20px;
      background-color:${({ theme }) => theme.colors.grayscale.white()};
      margin-bottom: -2px;

      ${({ theme }) => theme.mediaQuery.md(`
        height: 114px;
      `)}
    }
  }
`

export const StyledErrorPageWave = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
`

export const StyledErrorPageContent = styled.div`
  max-width: 750px;
  width: 100%;
  padding-top: ${({ theme }) => theme.rem(100)};

  ${({ theme }) => theme.paddingX(32)};

  ${({ theme }) => theme.mediaQuery.md(`
    ${theme.paddingY(100)}
  `)}

  h1{
    text-transform: uppercase;
    ${({ theme }) => theme.typo('h4')}
    margin-bottom: ${({ theme }) => theme.rem(32)}
  }

  svg{
    height: ${({ theme }) => theme.rem(100)};
  }

  .ErrorLink{
    display: table;
    margin:  ${({ theme }) => theme.rem(32)} auto 0;
    border-radius:  ${({ theme }) => theme.rem(32)};
    background-color:  ${({ theme }) => theme.colors.primary.default()};
    color:  ${({ theme }) => theme.colors.grayscale.white()};
    ${({ theme }) => theme.paddingY(20)};
    ${({ theme }) => theme.paddingX(24)};

    &:hover{
      text-decoration: underline;
    }
  }
`
