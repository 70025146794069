import React from 'react'

import { Section } from '@/atoms/Layout'
import Icon from '@/atoms/Icon'
import Wave from '@/molecules/Wave'
import WithHeading from '@/molecules/WithHeading'
import Link from '@/atoms/Link'

import {
  StyledErrorPage,
  StyledErrorPageContent,
  StyledErrorPageWave
} from './styles'

import Richtext from '@/atoms/RichText'
import { Label } from '@/atoms/Typography'

const ErrorPage = () => (
  <StyledErrorPage
    as={Section}>
    <StyledErrorPageWave>
      <Wave id="topWave" className="ErrorWave" position="top" template="primary" />
    </StyledErrorPageWave>

    <StyledErrorPageContent>


      <Icon icon="error" useIconColor unlockedProportions/>
      <WithHeading
        $decorator={false}
        heading={{
          title: 'oh NO! NON TROVIAMO LA PAGINA CHE CERCHI',
          titleTag: 'h1'
        }}
      />
      <Richtext>
        Ci dispiace, la pagina che stai cercando non è disponibile o non esiste. Prova a digitare nuovamente la url o riparti dalla Homepage.
      </Richtext>
      <Link className="ErrorLink" href="/">
        <Label $title bold typo="button1" uppercase>Torna alla home</Label>
      </Link>
    </StyledErrorPageContent>
  </StyledErrorPage>
)


export default ErrorPage
